import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

const Footer = () => {
    return (
        <footer>
            <div className='footer'>
                <p>&copy; Katalyst</p>
                <div className='footer__links'>
                    <Link to='/privacy'>Privacy</Link>
                    <Link to='/opt_out'>Do Not Sell/Share My Personal Information</Link>
                    <Link to='/disclosure-sensitive-info'>Limit the Use of My Sensitive Personal Information</Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
