import React from 'react';
import logo from '../../images/logo_mini.svg';
import './index.scss';

const MainPage = () => {
    return (
        <main>
            <div className='content'>
                <p>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}A consented, 1st-party data engine with
                    ~100M consumer profiles, attached to >10B full- funnel, real-time events
                </p>
                <a href='mailto:info@katalystinsights.com'>
                    <img src={logo} alt='logo' />
                    Contact us
                </a>
            </div>
        </main>
    );
};

export default MainPage;
