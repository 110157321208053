import React from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import bg from 'images/maiv_bg.svg';
import Footer from './components/Footer';
import Header from './components/Header';

const App = ({ children }) => {
    const location = useLocation();
    return (
        <div className='container'>
            <Header />
            <div className={cn({ 'main-bg': true, 'no-opacity': location.pathname === '/' })}>
                <img alt='katalyst-background' src={bg} />
            </div>
            {children}
            <Footer />
        </div>
    );
};

export default App;
