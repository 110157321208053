import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/Katalyst.svg';
import './index.scss';

const Header = () => {
    return (
        <header>
            <div className='header'>
                <Link to='/'>
                    <img alt='logo-katalyst' src={logo} />
                </Link>
            </div>
        </header>
    );
};

export default Header;
