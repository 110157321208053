import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

const DisclosureSensitiveInfo = () => {
    return (
        <div className='disclosure-sensitive-info'>
            <div className='disclosure-sensitive-info-mid-area'>
                <div className='disclosure-sensitive-info-list-head'>
                    <div className='disclosure-sensitive-info-list-head-left'>
                        <h1 className='disclosure-sensitive-info-list-head-left__title'>
                            Right to Limit Use/Disclosure of Sensitive Information
                        </h1>
                    </div>
                    <div className='disclosure-sensitive-info-list-head-right'>
                        <p>Last Updated August 10, 2023</p>
                    </div>
                </div>
                <p>
                    To opt out concerning use and disclosure of your sensitive personal information (as defined under
                    and described in our privacy policy and/or an applicable law), please submit a request by emailing
                    the following info to <a href='mailto:privacy@katalystinsights.com'>privacy@katalystinsights.com</a>
                    .
                </p>
                <ul>
                    <li>First name</li>
                    <li>Last name</li>
                    <li>Email</li>
                    <li>Street Address</li>
                    <li>City</li>
                    <li>State</li>
                    <li>Postal Code</li>
                    <li>Country</li>
                </ul>
                <br />
                <p>
                    The right to opt-out is subject to certain exemptions and exceptions. Additionally, we may be unable
                    to fulfill your request if we believe the request is fraudulent.
                </p>
                <p>
                    Please visit our <Link to='/privacy'>Privacy Policy</Link> for information about your rights.
                </p>
            </div>
        </div>
    );
};

export default DisclosureSensitiveInfo;
