import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

const OptOut = () => {
    return (
        <div className='opt-out'>
            <div className='opt-out-mid-area'>
                <div className='opt-out-list-head'>
                    <div className='opt-out-list-head-left'>
                        <h1 className='opt-out-list-head-left__title'>Right to Opt Out of Sale of Information</h1>
                    </div>
                    <div className='opt-out-list-head-right'>
                        <p>Last Updated August 10, 2023</p>
                    </div>
                </div>
                <p>
                    To opt out of the sale of your personal information, please submit a request by emailing the
                    following info to <a href='mailto:privacy@katalystinsights.com'>privacy@katalystinsights.com</a>.
                </p>
                <ul>
                    <li>First name</li>
                    <li>Last name</li>
                    <li>Email</li>
                    <li>Street Address</li>
                    <li>City</li>
                    <li>State</li>
                    <li>Postal Code</li>
                    <li>Country</li>
                </ul>
                <br />
                <p>
                    The right to opt-out is subject to certain exemptions and exceptions. For example, it does not apply
                    to personal information that we share with service providers that perform certain business functions
                    for us pursuant to a written contract. Additionally, we may be unable to fulfill your request if we
                    believe the request is fraudulent.
                </p>
                <p>
                    Please visit our <Link to='/privacy'>Privacy Policy</Link> for additional information about your
                    rights.
                </p>
            </div>
        </div>
    );
};

export default OptOut;
