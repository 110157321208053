import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DisclosureSensitiveInfo from './components/DisclosureSensitiveInfo';
import MainPage from './components/MainPage';
import OptOut from './components/OptOut';
import Privacy from './components/Privacy';
import App from './App';
import './styles/index.scss';

const elements = (
    <BrowserRouter basename='/'>
        <App>
            <Switch>
                <Route exact path='/' component={MainPage} />
                <Route exact path='/privacy' component={Privacy} />
                <Route exact path='/opt_out' component={OptOut} />
                <Route exact path='/disclosure-sensitive-info' component={DisclosureSensitiveInfo} />
            </Switch>
        </App>
    </BrowserRouter>
);
createRoot(document.getElementById('root')).render(elements);
